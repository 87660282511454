/* You can add global styles to this file, and also import other style files */
.btn-open-app {
  background-color: #8acdac;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #f9f6ee;
  cursor: pointer;
  display: inline-block;
  font-family: 'Haas Grot Text R Web', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 1.5rem 0 0 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: '100px';
}
.btn-open-app:hover {
  background-color: rgba(138, 205, 172, 0.5);
}
